import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressDialog = _resolveComponent("ProgressDialog")!

  return (_openBlock(), _createBlock(_component_ProgressDialog, {
    title: "Подбор пакетов...",
    controller: _ctx.controller,
    progressMethodName: "packagesSelectionProgress",
    progressEventName: "packagesSelectionProgressEvent",
    messageEventName: "packagesSelectionMessageEvent",
    stopMethod: "StopSelection",
    ref: "progressDialog",
    onProgressClose: _ctx.onProgressClose
  }, null, 8, ["controller", "onProgressClose"]))
}