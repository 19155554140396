
import { defineComponent } from "vue";
import ProgressDialog from "@/components/workflows/progressDialog.vue";
import TokenService from "@/services/tokenService";
import axios from "axios";

export default defineComponent({
  components: {
    ProgressDialog,
  },
  emits: ["progressClose"],
  methods: {
    start(idParent: any) {
      this.progressDialog().show(
        true,
        {
          startCallbackAsync: async (jobId, idParent) => {
            const formData = new FormData();
            formData.append("jobId", jobId);

            const params = {};
            if (idParent) {
              params["IdParent"] = idParent;
            }

            await axios
              .create({ baseURL: "/" })
              .post(`${this.controller}/StartSelection`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + TokenService.getLocalAccessToken(),
                },
                params,
              });
          },
        },
        idParent
      );
    },
    progressDialog() {
      return this.$refs.progressDialog as any;
    },
    onProgressClose() {
      this.$emit("progressClose");
    },
  },
  data() {
    return {
      controller: "PlanPackage",
    };
  },
});
